<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="labels"
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import * as _ from 'lodash';
import moment from 'moment';

export default {
  name: 'CChartLineExample',
  components: { CChartLine },
  data () {
    return {
      labels: []
    }
  },
  props: {
    data: {
      default: null,
      required: true
    },
    sites: {
      default: null,
      type: Array,
      required: true
    },
    summary: {
      default: null,
      type: String,
      required: true
    }
  },
  computed: {
    defaultDatasets () {
      let data = [];
      let backgroundColor = [
        'rgb(228,102,81,0.9)',
        'rgb(0,216,255,0.9)',
        'rgb(0,128,0,0.9)',
        '#DD1B16'
      ];

      this.sites.forEach((item, i) => {
        let items = {};
        items['label'] = item;

        let total = this.data.filter(function(row) {
    			return (row.site_name === item);  
				})

        let num = [];
        total.forEach(int => {
          num.push(int.total);
        })
        items['data'] = num;
        items['backgroundColor'] = backgroundColor[i];

        data.push(items)
      });

      let days = [];
      this.data.forEach(day => {
        switch(this.summary) {
          case 'day':
            // code block
            days.push(day.date)
            break;
          case 'month':
            // code block
            days.push(this.date( new Date('2021-' + day.month + '-1'), 'MMMM'));
            break;
          default:
            // code block
            days.push(day.year)
        }
      });

      days = days.filter((day, idx) => days.indexOf(day) === idx);

      this.labels = days;

      return data;
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true,
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    },
  },
  methods: {
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
  }
}
</script>
